import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID,} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {NavbarComponent} from '@shared/component-utils/navbar/navbar.component';
import {ProfileDropdownComponent} from './pages/user/profile/profile-dropdown/profile-dropdown.component';
import {navbarItems, SUPER_USER_PERMISSION} from './app.props';
import {I18nService} from '@shared/core/services/i18n.service';
import {ToastModule} from 'primeng/toast';
import {isPlatformBrowser, NgClass} from '@angular/common';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@shared/core/services/authentication.service';
import {PermissionService} from '@shared/core/services/permission.service';
import {NavBarItemModel} from '@core/models/navbar.model';
import {ProgressSpinnerComponent} from '@shared/component-utils/progress-spinner/progress-spinner.component';
import {SpinnerService} from '@shared/core/services/spinner.service';
import {SignalService} from '@shared/core/services/signal.service';
import {TooltipModule} from 'primeng/tooltip';
import {FilterMatchMode, PrimeNGConfig} from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, ProfileDropdownComponent, ToastModule, ProgressSpinnerComponent, NgClass, TooltipModule],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  navbarItems: NavBarItemModel[] = [];
  isLoginPage: boolean = true;
  subscription: Subscription = new Subscription();
  username: string = '';

  constructor(private i18n: I18nService,
              primeNGConfig: PrimeNGConfig,
              private router: Router,
              private cd: ChangeDetectorRef, // Inject ChangeDetectorRef
              private auth: AuthenticationService,
              public spinnerService: SpinnerService,
              private permissionService: PermissionService,
              private signalService: SignalService,
              @Inject(PLATFORM_ID) private readonly platformId: Object) {
    primeNGConfig.filterMatchModeOptions = <any>{
      text: [FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
      numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
      date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER],
      enum: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.auth.isUserAuthenticated();
    this.i18n.setUpLanguages();
    if (isPlatformBrowser(this.platformId)) {
      const body = document.getElementsByTagName('body')[0];
      this.i18n.onLangChange().subscribe((res) => {
        body.className = res.lang === 'ar' ? 'rtl' : '';
        this.cd.detectChanges();
      });
    }
    this.checkIfLoginPage();
  }

  checkIfLoginPage(): void {
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isLoginPage = event.url === '/login';
          if (!this.isLoginPage) {
            this.navbarItems = this.buildNavbar(navbarItems);
            const name = this.signalService.employeeName();
            if (name) {
              this.username = name;
            }

          }
        }
      })
    );
  }

  buildNavbar(items: NavBarItemModel[]): NavBarItemModel[] {
    return items
      .map(item => {
        if (item.items && item.items.length > 0) {
          const filteredChildren = this.buildNavbar(item.items);
          if (filteredChildren.length > 0) {
            return {
              ...item,
              items: filteredChildren
            };
          }
          return null;
        }
        let permission = item.permission;
        if (permission !== SUPER_USER_PERMISSION) {
          permission = item.permission + '_VIEW';
        }
        return this.permissionService.isPermitted(permission) ? item : null;
      })
      .filter(item => item !== null) as NavBarItemModel[];
  }
}
