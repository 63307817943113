import {Component, effect, OnInit} from '@angular/core';
import {NavBarItemModel} from '@core/models/navbar.model';
import {MenubarModule} from 'primeng/menubar';
import {CommonModule} from '@angular/common';
import {MultiCheckboxComponent} from '@shared/input/checkbox/multi-checkbox/multi-checkbox.component';
import {RadioButtonComponent} from '@shared/input/radio-button/radio-button.component';
import {Router} from '@angular/router';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {environment} from "../../../../../environments/environment";
import {AuthenticationService} from "@shared/core/services/authentication.service";
import {SignalService} from "@shared/core/services/signal.service";
import {MapType} from "@shared/core/services/map.service";
import {subNavbar} from "../../../../app.props";
import {EmployeeService} from "../../../employees/employees/employee.service";

@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [
    MenubarModule,
    CommonModule,
    MultiCheckboxComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './profile-dropdown.component.html',
})
export class ProfileDropdownComponent implements OnInit {
  items: NavBarItemModel[] = subNavbar;
  checkControl = new FormControl();
  radioControl = new FormControl();
  profileImage: string | null = null;
  readonly userIcon = 'assets/header-icons/user-icon.png'

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private signalService: SignalService,
    private employeeService: EmployeeService,
  ) {
    effect(() => {
      if (this.signalService.profileImageSignal()) {
        this.profileImage = this.signalService.profileImageSignal();
      } else {
        this.profileImage = this.userIcon;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.radioControl.setValue(this.signalService.tileSignal())
    if (environment.isLocal) {
      this.items[0].items?.push({
        label: 'NAVBAR.SUB_NAVBAR.DEMO',
        icon: 'pi pi-desktop',
        key: 'DEMO'
      });
    }


    this.checkControl.valueChanges.subscribe(() => {
      //to be defined later;
    });

    this.radioControl.valueChanges.subscribe((res) => {
      this.signalService.tileSignal.set(res as MapType)
    });
  }

  async onItemClick(event: Event, item: NavBarItemModel) {
    if (event.defaultPrevented) {
      event.stopPropagation();
    }
    if (item.items?.length) return;
    switch (item.key) {
      case 'PROFILE':
        this.router.navigate(['profile']);
        break;
      case 'SIGN_OUT':
        await this.authenticationService.logout();
        localStorage.clear()
        break;
      case 'DEMO':
        this.router.navigate(['demo']);
        break;
    }
  }

}
